import React, { useEffect, useState } from 'react';
import logo from '../media/logo.png';
import amazon from '../media/amazon.com.png';
import walmart from '../media/walmart.com.png';
import ebay from '../media/ebay.com.png';
import bestbuy from '../media/bestbuy.com.png';

const RedirectingPage = () => {
  const queryString = new URLSearchParams(window.location.search);
  const targetUrl = queryString.get('target') || '';
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (targetUrl) {
      const interval = setInterval(() => {
        setProgress((prev) => {
          if (prev >= 100) {
            clearInterval(interval);
            window.location.href = targetUrl;
          }
          return prev + 5;
        });
      }, 100);
    }
  }, [targetUrl]);

  // Target domain'e göre logo belirleme
  const targetDomain = targetUrl ? new URL(targetUrl).hostname : '';
  const logos = {
    amazon: amazon,
    walmart: walmart,
    ebay: ebay,
    bestbuy: bestbuy,
  };

  const getTargetLogo = () => {
    if (targetDomain.includes('amazon')) return logos.amazon;
    if (targetDomain.includes('walmart')) return logos.walmart;
    if (targetDomain.includes('ebay')) return logos.ebay;
    if (targetDomain.includes('bestbuy')) return logos.bestbuy;
    return null; // Varsayılan logo eklenebilir
  };

  const targetLogo = getTargetLogo();

  return (
    <div
      style={{
        fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
        backgroundColor: '#f9f9f9',
        color: '#333',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
      }}
    >
      {/* Marka Logosu */}
      <div
        style={{
          width: '8%',
          borderRadius: '10%',
          background: 'rgba(0, 0, 0, 0.8)',
          padding: '30px 0',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <img src={logo} alt='Marka Logosu' style={{ width: '120px' }} />
      </div>

      {/* Başlık */}
      <h1 style={{ fontSize: '2rem', margin: '20px 0 10px' }}>Yönlendiriliyorsunuz...</h1>
      <p style={{ fontSize: '1rem', marginBottom: '30px', color: '#555' }}>
        Birkaç saniye içinde sayfaya yönlendirileceksiniz.
      </p>

      {/* Nokta Nokta İlerleyen Progress Bar */}
      <div style={{ display: 'flex', gap: '8px', marginBottom: '30px' }}>
        {Array.from({ length: 5 }).map((_, i) => (
          <div
            key={i}
            style={{
              width: '15px',
              height: '15px',
              borderRadius: '50%',
              backgroundColor: i * 20 < progress ? '#0078FF' : '#e0e0e0',
              transition: 'background-color 0.3s ease',
            }}
          ></div>
        ))}
      </div>

      {/* Spinner */}
      <div
        style={{
          marginBottom: '30px',
          border: '4px solid #e0e0e0',
          borderTop: '4px solid #0078FF',
          borderRadius: '50%',
          width: '40px',
          height: '40px',
          animation: 'spin 1s linear infinite',
        }}
      ></div>

      {/* Hedef Sayfa Logosu ve Bağlantı */}
      {targetUrl && (
        <>
          {targetLogo && (
            <img src={targetLogo} alt='Target Site Logo' style={{ width: '140px', marginBottom: '10px' }} />
          )}
          <p>
            Yönlendirme gerçekleşmezse{' '}
            <a
              href={targetUrl}
              style={{ color: '#0078FF', textDecoration: 'none', fontWeight: 'bold' }}
              rel='noopener noreferrer'
            >
              buraya tıklayın
            </a>
            .
          </p>
        </>
      )}

      {/* Spinner Animasyonu */}
      <style>
        {`
          @keyframes spin {
            from { transform: rotate(0deg); }
            to { transform: rotate(360deg); }
          }
        `}
      </style>
    </div>
  );
};

export default RedirectingPage;
