import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import RedirectingPage from './components/RedirectingPage';
import ProductList from './components/ProductList';

function App() {
  return (
    <Router>
      <div style={{ backgroundColor: '#f4f4f4', minHeight: '100vh', padding: '20px' }}>
        <h1 style={{ textAlign: 'center', marginBottom: '20px' }}>Shop Eronware Redirecting Service</h1>
        <Routes>
          {/* Ana sayfa veya başka bir bileşen */}
          <Route path='/' element={<ProductList />} />

          {/* Yönlendirme sayfası */}
          <Route path='/redirecting' element={<RedirectingPage />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
