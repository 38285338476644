import React from 'react';
import { Card, CardMedia, CardContent, Typography, Button } from '@mui/material';

function ProductCard({ product }) {
  const handleRedirect = () => {
    let store;
    switch (product.marketplace) {
      case 'amazon':
        store = 'amazon.com';
        break;
      case 'walmart':
        store = 'walmart.com';
        break;
      case 'ebay':
        store = 'ebay.com';
        break;
      case 'bestbuy':
        store = 'bestbuy.com';
        break;
      default:
        console.error('Bilinmeyen marketplace:', product.marketplace);
        return;
    }
    const redirectURL = `/redirect?store=${store}&id=${product.id}`;
    window.location.href = redirectURL;
  };

  return (
    <Card
      sx={{
        maxWidth: 345,
        borderRadius: 4,
        boxShadow: '0 10px 30px rgba(0, 0, 0, 0.1)',
        background: 'linear-gradient(to bottom, #ffffff, #f9f9f9)',
        transition: 'transform 0.3s, box-shadow 0.3s',
        '&:hover': {
          transform: 'scale(1.05)',
          boxShadow: '0 15px 40px rgba(0, 0, 0, 0.2)',
        },
      }}
    >
      <CardMedia
        component='img'
        height='200'
        image={product.image}
        alt={product.title}
        sx={{
          objectFit: 'cover',
          borderBottom: '1px solid #ddd',
          transition: 'transform 0.5s',
          '&:hover': {
            transform: 'scale(1.1)',
          },
        }}
      />
      <CardContent sx={{ textAlign: 'center' }}>
        <Typography
          variant='h6'
          sx={{
            fontWeight: 'bold',
            fontSize: '1.1rem',
            color: '#333',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {product.title}
        </Typography>
        <Typography variant='body1' sx={{ color: '#555', margin: '10px 0', fontSize: '1.2rem' }}>
          ${product.price}
        </Typography>
        <Button
          variant='contained'
          onClick={handleRedirect}
          fullWidth
          sx={{
            background: 'linear-gradient(90deg, #4A90E2, #007BFF)',
            color: '#fff',
            fontWeight: 'bold',
            padding: '10px 0',
            transition: 'background 0.3s',
            '&:hover': {
              background: 'linear-gradient(90deg, #007BFF, #0056b3)',
            },
          }}
        >
          Go to Product
        </Button>
      </CardContent>
    </Card>
  );
}

export default ProductCard;
