import React, { useState, useEffect } from 'react';
import ProductCard from './ProductCard';
import { Grid, MenuItem, Select, FormControl, InputLabel, Typography } from '@mui/material';
import axios from 'axios';

function ProductList() {
  const [products, setProducts] = useState([]);
  const [marketplace, setMarketplace] = useState('amazon');

  const fetchProducts = async () => {
    try {
      const response = await axios.get(`https://api.eronware.com/shop/get?marketplace=${marketplace}`);
      setProducts(response.data.data);
    } catch (error) {
      console.error('Error fetching products:', error);
    }
  };

  useEffect(() => {
    fetchProducts();
  }, [marketplace]);

  return (
    <div
      style={{
        padding: '40px',
        background: 'linear-gradient(to bottom, #f9f9f9, #e9e9e9)',
        minHeight: '100vh',
      }}
    >
      <Typography
        variant='h4'
        sx={{
          textAlign: 'center',
          marginBottom: 4,
          fontWeight: 'bold',
          color: '#333',
          animation: 'fadeIn 1.5s ease',
        }}
      >
        Discover Products from Top Marketplaces
      </Typography>
      <div style={{ marginBottom: '30px', textAlign: 'center' }}>
        <FormControl variant='outlined' sx={{ minWidth: 300 }}>
          <InputLabel>Marketplace</InputLabel>
          <Select
            value={marketplace}
            onChange={(e) => setMarketplace(e.target.value)}
            label='Marketplace'
            sx={{
              backgroundColor: '#fff',
              boxShadow: '0 4px 10px rgba(0,0,0,0.1)',
              borderRadius: 2,
              transition: 'box-shadow 0.3s',
              '&:hover': {
                boxShadow: '0 6px 14px rgba(0,0,0,0.2)',
              },
            }}
          >
            <MenuItem value='amazon'>Amazon</MenuItem>
            <MenuItem value='walmart'>Walmart</MenuItem>
            <MenuItem value='ebay'>eBay</MenuItem>
            <MenuItem value='bestbuy'>BestBuy</MenuItem>
          </Select>
        </FormControl>
      </div>
      <Grid container spacing={4} justifyContent='center'>
        {products.map((product) => (
          <Grid item key={product.id}>
            <ProductCard product={product} />
          </Grid>
        ))}
      </Grid>
    </div>
  );
}

export default ProductList;
